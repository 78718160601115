import { IUser } from "./types";

export interface UserState {
  user: IUser;
  isRegistered: boolean;
}

export const state = (): UserState => ({
  user: Object(),
  isRegistered: Boolean()
});
