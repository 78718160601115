import i18n from "../../i18n";
import Notifications, { notify } from "@kyvg/vue3-notification";

interface IData {
  title: string;
  text: string;
}

class _Notifications {
  private duration = 5000;

  public success(data: IData | string) {
    return this._notify(data, "success");
  }

  public info(data: IData | string) {
    return this._notify(data, "info");
  }

  public warn(data: IData | string) {
    return this._notify(data, "warn");
  }

  public error(data: IData | string) {
    return this._notify(data, "error");
  }

  private _notify(data: IData | string, type: string) {
    let title = i18n.global.t("notifications." + type);
    let text = data;

    if (typeof data !== "string") {
      title = data.title ? data.title : title;
      text = data.text || "";
    }

    return notify({
      title,
      text: text as string,
      duration: this.duration,
      type
    });
  }
}

export default {
  install: (app: any) => {
    app.use(Notifications);
    app.config.globalProperties.$notify = new _Notifications();
  }
};

export const Notify = new _Notifications();
