import { RouteRecordRaw } from "vue-router";
import { store } from "@/store/";
import { ICheckoutProduct } from "@/store/checkout/types";

export default [
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("./index.vue"),
    beforeEnter: (to, from, next) => {
      const isAuthenticated: boolean = store.getters["user/isAuthenticated"];
      const products: Array<ICheckoutProduct> =
        store.getters["checkout/products"];

      if (to.name !== "Login" && !isAuthenticated) {
        next({ name: "Login" });
      }

      return !products.length ? next(from) : next();
    }
  },
  {
    path: "/checkout/success",
    name: "Checkout Success",
    component: () => import("./success/index.vue")
  }
] as Array<RouteRecordRaw>;
