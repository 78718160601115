<template>
  <ul class="hiper-links">
    <li v-for="(item, index) in extraLinks" :key="index">
      <router-link @click="toggleMenuAndScroll(false)" :to="item.link">
        {{ item.text }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import IconArrow from "@/assets/img/icons/arrow.svg";

export default {
  name: "MenuNavigation",
  data: () => ({
    IconArrow
  }),
  props: ["toggleMenu", "links"],
  computed: {
    extraLinks() {
      if (this.links?.length) {
        return this.links;
      }

      return [];
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toggleMenuAndScroll(value) {
      if (this.toggleMenu) {
        this.toggleMenu(value);
      }
      this.scrollToTop();
    }
  }
};
</script>
