<template>
  <nav id="navbar" ref="navbar" :class="{ opened: isMenuOpen }">
    <div class="container">
      <div class="mobile-toggle">
        <button @click="toggleMenu()">
          <inline-svg
            v-if="isMenuOpen"
            :src="IconMenuClose"
            title="Menu Icon Close"
          />
          <inline-svg v-else :src="IconMenu" title="Menu Icon" />
        </button>
      </div>

      <!-- Brand -->
      <div class="brand">
        <router-link @click="toggleMenuAndScroll(false)" to="/">
          <inline-svg :src="Logo" title="Logo SM"
        /></router-link>
      </div>

      <Menu :links="menuPrincipal" :toggleMenu="toggleMenu" />
      <MenuMore
        v-if="!this.isMobile()"
        :links="menuMore"
        :toggleMenu="toggleMenu"
      />
      <MenuSocial :toggleMenu="toggleMenu" :showLogin="true" />

      <!-- Social Links -->
    </div>
  </nav>
</template>

<script>
import Logo from "@/assets/img/logo/sm-logo.svg";

import IconMenu from "@/assets/img/icons/mobile-menu.svg";
import IconMenuClose from "@/assets/img/icons/mobile-menu-close.svg";

import Menu from "./Menu.vue";
import MenuMore from "./MenuMore.vue";
import MenuSocial from "./MenuSocial.vue";

export default {
  name: "NavbarElement",
  components: {
    Menu,
    MenuMore,
    MenuSocial
  },
  data() {
    return {
      Logo,
      IconMenu,
      IconMenuClose,
      isMenuOpen: false,
      menuPrincipal: [],
      menuMore: []
    };
  },
  mounted() {
    const navbarEl = this.$refs["navbar"];

    this.menuPrincipal = [
      {
        link: "/",
        text: this.$t("navbar.links.home")
      },
      {
        link: "/tickets",
        text: this.$t("navbar.links.tickets")
      },
      // {
      //   link: "/certificate",
      //   text: this.$t("navbar.links.certificate")
      // },
      {
        link: "/dance-pack",
        text: this.$t("navbar.links.dance_pac")
      },
      {
        link: "/classes",
        text: this.$t("navbar.links.classes")
      },
      {
        link: "/teachers",
        text: this.$t("navbar.links.teachers")
      }
      // {
      //   link: "/djs",
      //   text: this.$t("navbar.links.djs")
      // }
      // {
      //   link: "/team",
      //   text: this.$t("navbar.links.team")
      // },
      // {
      //   link: "/events",
      //   text: this.$t("navbar.links.events")
      // },
      // {
      //   link: "/about",
      //   text: this.$t("navbar.links.about")
      // }
    ];

    this.menuMore = [
      {
        link: "/team",
        text: this.$t("navbar.links.team")
      },
      // {
      //   link: "/events",
      //   text: this.$t("navbar.links.events")
      // },
      {
        link: "/about",
        text: this.$t("navbar.links.about")
      }
    ];

    console.log(this.isMobile());
    if (this.isMobile()) {
      this.menuPrincipal = this.menuPrincipal.concat(this.menuMore);
      this.menuMore = [];
    }

    if (location.pathname === "/") {
      window.addEventListener("scroll", () => {
        const wn = window.scrollY;
        const goPoint = 80;

        if (wn > goPoint) {
          navbarEl.classList.add("navbar-solid");
        } else {
          navbarEl.classList.remove("navbar-solid");
        }
      });
    } else {
      navbarEl.classList.add("navbar-solid");
    }
  },
  methods: {
    toggleMenu(value) {
      this.isMenuOpen = value !== undefined ? value : !this.isMenuOpen;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/_mixin";

#navbar {
  background-color: var(--color-sm-primary-opacity-50);
  height: var(--sizing-55);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
  padding: {
    top: 0;
    bottom: 0;
    left: var(--spacing-15);
    right: var(--spacing-15);
  }
  @include transition(background-color 0.3s ease-in-out);

  &.opened {
    background-color: var(--color-sm-primary);
    padding-bottom: var(--spacing-25);
    @include device(mobile) {
      height: auto;
      ul.hiper-links,
      ul.social-links {
        display: flex !important;
      }
    }
  }

  .mobile-toggle {
    display: none;
    height: var(--sizing-55);
    width: var(--sizing-60);
    min-height: var(--sizing-55);
    @include device(mobile) {
      display: flex;
    }

    button {
      background: none;
      padding: {
        top: 0;
        bottom: 0;
        left: var(--spacing-20);
        right: var(--spacing-20);
      }
    }
  }

  &.navbar-solid {
    background-color: var(--color-sm-primary);
  }

  > div {
    height: 100%;
    display: flex;

    @include device(mobile) {
      flex-direction: column;
    }
  }

  .brand {
    margin-right: var(--spacing-32);
    @include device(mobile) {
      position: absolute;
      left: calc(50% - var(--spacing-20));
      top: 5px;
    }

    a {
      height: 100%;
      display: flex;
      align-items: center;

      @include device(mobile) {
        display: block;
      }
    }
  }

  ul {
    display: flex;
    height: 100%;

    &.right {
      margin-left: auto;
    }

    &.hiper-links {
      @include device(mobile) {
        display: none;
        flex-direction: column;
        margin-top: var(--spacing-25);
        height: auto;
      }

      li a {
        padding: {
          top: 0;
          bottom: 0;
          left: var(--spacing-15);
          right: var(--spacing-15);
        }
        @include device(mobile) {
          height: var(--sizing-55);
          text-align: center;
          width: 100%;
          display: block;
          padding: {
            top: var(--spacing-20);
            bottom: var(--spacing-20);
            left: var(--spacing-20);
            right: var(--spacing-20);
          }
        }
      }
    }
    &.social-links {
      @include device(mobile) {
        display: none;
        margin-right: auto;
        height: var(--sizing-55);
      }

      li a {
        padding: {
          top: 0;
          bottom: 0;
          left: var(--spacing-10);
          right: var(--spacing-10);
        }

        svg {
          fill: var(--color-white) !important;
          height: var(--sizing-20);
          width: var(--sizing-20);
        }
      }
    }

    li {
      color: var(--color-white);

      a,
      &.dropdown .label {
        height: 100%;
        display: flex;
        align-items: center;
        @include transition(background-color 0.1s ease-in-out);

        &:hover {
          background-color: var(--color-white-opacity-20);
        }
      }

      &.dropdown {
        position: relative;

        &:hover .list {
          display: flex;
        }

        .label {
          cursor: pointer;
          padding: 0 15px;
          svg {
            height: 12px;
            transform: rotate(-90deg);
            margin-left: 8px;
            path {
              fill: white;
            }
          }
        }
        .list {
          height: auto;
          position: absolute;
          background-color: var(--color-sm-primary);
          display: none;
          flex-direction: column;

          li a {
            padding: {
              top: var(--spacing-15);
              bottom: var(--spacing-15);
              left: var(--spacing-20);
              right: var(--spacing-20);
            }
            white-space: nowrap;
          }
        }
      }

      &.btn-header {
        display: flex;
        align-items: center;
        margin-left: var(--spacing-10);

        a {
          height: var(--sizing-30);
          padding: {
            top: var(--spacing-10);
            bottom: var(--spacing-10);
            left: var(--spacing-25);
            right: var(--spacing-25);
          }
          border: var(--border-size-1) solid var(--color-white);
          border-radius: var(--border-radius-150);
          font-size: var(--font-size-14);

          &.profile {
            padding: {
              top: var(--spacing-10);
              bottom: var(--spacing-10);
              left: var(--spacing-10);
              right: var(--spacing-10);
            }
            width: auto;
            height: 100%;
            border: none;
            border-radius: 0;
          }
        }
      }
    }
  }
}
</style>
