import { ActionContext } from "vuex";
import * as types from "./types";
import { UserState, IUser } from "./types";
import { State } from "../";

type Context = ActionContext<UserState, State>;

const actions = {
  /**
   * Salva os dados do usuário na sessão
   * @param context
   * @param payload
   */
  setUser(context: Context, payload: IUser) {
    context.commit(types.SET_USER, payload);
  },

  /**
   * Salva se o usuário está ou não registrado no evento vigente
   * @param context
   * @param payload
   */
  setIsRegistered(context: Context, payload: Boolean) {
    context.commit(types.SET_IS_REGISTERED, payload);
  },

  /**
   * Remove o usuário sa sessão
   * @param context
   */
  removeUser(context: Context) {
    context.commit(types.LOGOUT_USER);
  }
};
export { actions };
