export const SET_USER = "SET_USER";
export const SET_IS_REGISTERED = "SET_IS_REGISTERED";
export const LOGOUT_USER = "LOGOUT_USER";

export interface IUser {
  readonly id: string;
  type_id?: string;
  name: string;
  phone: string;
  email: string;
  password: string;
  token?: string;
  city: string;
  state: string;
  country: string;
  cpf?: string;
  birthday: Date;
  created_at?: Date;
  updated_at?: Date;
}

export interface IUserUpdate {
  id?: string;
  type_id?: string;
  name?: string;
  phone?: string;
  email?: string;
  password?: string;
  token?: string;
  city: string;
  state?: string;
  country?: string;
  cpf?: string;
  birthday?: Date;
  created_at?: Date;
  updated_at?: Date;
}

export interface UserState {
  user: IUser;
  isRegistered: Boolean;
}
