import { ActionContext } from "vuex";
import * as types from "./types";
import { CertificateState, ICertificate } from "./types";
import { State } from "../";

type Context = ActionContext<CertificateState, State>;

const actions = {
  /**
   * Salva os certificados
   * @param context
   * @param payload
   */
  setCertificates(context: Context, payload: ICertificate[]) {
    context.commit(types.SET_CERTIFICATE, payload);
  }
};
export { actions };
