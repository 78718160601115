import { ICookieStorage } from "../utils/interfaces/";

export class CookieStorageService {
  /*
   * General utils for managing cookies in Typescript.
   */
  public static set(key: string, value: object | boolean) {
    const date = new Date();

    // Set it expire in 1 days
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);

    // Set it
    document.cookie =
      key + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
  }

  public static get(key: string): ICookieStorage | null {
    try {
      const value = "; " + document.cookie;
      const parts = value.split("; " + key + "=");
      const data = parts.pop()?.split(";").shift();

      if (data) {
        return { data: JSON.parse(data) };
      }
    } catch (error) {
      console.error("Couldn't convert to object.");
    }

    return null;
  }

  public static delete(key: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

    // Set it
    document.cookie = key + "=; expires=" + date.toUTCString() + "; path=/";
  }
}
