const paymentEnum = {
  PAG_SEGURO: "pag-seguro",
  CREDIT_CARD: "credit-card",
  BILLET: "billet",
  PIX: "pix",
  DEBIT: "debit",
  DEPOSIT: "deposit"
};

type IPaymentEnum = "pag-seguro" | "credit-card" | "billet" | "pix" | "debit";

export { paymentEnum, IPaymentEnum };
