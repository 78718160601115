import {
  CheckoutState,
  ICheckoutProduct,
  ICheckoutCreditCard,
  ICheckoutOrder
} from "./types";
import * as types from "./types";
import { IPaymentEnum } from "@/utils/enums/paymentEnum";

const mutations = {
  [types.ADD_PRODUCT](state: CheckoutState, payload: ICheckoutProduct) {
    state.products.push(payload);
  },

  [types.SAVE_CREDITCARD](state: CheckoutState, payload: ICheckoutCreditCard) {
    state.creditCard = payload;
  },

  [types.SAVE_PAYMENT_METHOD](state: CheckoutState, payload: IPaymentEnum) {
    state.paymentMethod = payload;
  },

  [types.SAVE_ORDER](state: CheckoutState, payload: ICheckoutOrder) {
    state.order = payload;
  },

  [types.SET_LOADING](state: CheckoutState, payload: boolean) {
    state.loading = payload;
  }
};

export { mutations };
