import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/expired",
    name: "Expired",
    component: () => import("./index.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      const token = to.redirectedFrom?.params?.token;

      if (!token) {
        next({ path: "/" });
      } else {
        next();
      }
    }
  }
] as Array<RouteRecordRaw>;
