import { IPaymentEnum } from "@/utils/enums/paymentEnum";
import {
  CheckoutState,
  ICheckoutProduct,
  ICheckoutCreditCard,
  ICheckoutOrder
} from "./types";

export const state = (): CheckoutState => ({
  products: Array<ICheckoutProduct>(),
  paymentMethod: String() as IPaymentEnum,
  creditCard: <ICheckoutCreditCard>Object(),
  order: <ICheckoutOrder>Object(),
  loading: Boolean()
});
