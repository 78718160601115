import { actions } from "./actions";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { CheckoutState } from "./types";

const checkout = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

export { checkout, CheckoutState };
