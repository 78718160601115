type IFunction = (data: any) => {};
class Event {
  private events: any = {};

  constructor() {
    this.events = {};
  }

  public on(eventName: string, fn: IFunction) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(fn);
  }

  public off(eventName: string, fn: IFunction) {
    if (this.events[eventName]) {
      for (let i = 0; i < this.events[eventName].length; i++) {
        if (this.events[eventName][i] === fn) {
          this.events[eventName].splice(i, 1);
          break;
        }
      }
    }
  }

  public emit(eventName: string, data: any) {
    if (this.events[eventName]) {
      this.events[eventName].forEach(function (fn: IFunction) {
        fn(data);
      });
    }
  }
}

let instance: Event | undefined = undefined;

function getInstance() {
  if (instance) {
    return instance;
  }

  instance = new Event();
  return instance;
}

export default {
  install: (app: any) => {
    app.config.globalProperties.$events = getInstance();
  }
};

export const events = getInstance();
