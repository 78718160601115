export const ADD_PRODUCT = "ADD_PRODUCT";
export const SAVE_CREDITCARD = "SAVE_CREDITCARD";
export const SAVE_PAYMENT_METHOD = "SAVE_PAYMENT_METHOD";
export const SAVE_ORDER = "SAVE_ORDER";
export const SET_LOADING = "SET_LOADING";

import { IPaymentEnum } from "@/utils/enums/paymentEnum";

export interface ICheckoutCreditCard {
  cardName: string;
  cardNumber: string;
  cardMonth: string;
  cardYear: string;
  cardCvv: string;
  cardCpfCnpj: number;
  cardInstallment: number;
  cardInstallmentValue: number;
  cardType: string;
}

export interface ICheckoutOrder {
  code: string;
  status: string;
}

export interface ICheckoutProduct {
  code: string;
  name: string;
  price: number;
  quantity: number;
}

export interface CheckoutState {
  products: Array<ICheckoutProduct>;
  paymentMethod: IPaymentEnum;
  creditCard: ICheckoutCreditCard;
  order: ICheckoutOrder;
  loading: boolean;
}

interface _ICheckoutPayment {
  method: IPaymentEnum;
  amount: number;
}

export type ICheckoutPayment = _ICheckoutPayment & ICheckoutCreditCard;
