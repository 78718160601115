import * as types from "./types";
import { ProductState, IProduct } from "./types";
import { SessionStorageService } from "../../services/sessionStorage.service";

const mutations = {
  [types.SET_TICKET](state: ProductState, payload: IProduct) {
    state.ticket = payload;

    SessionStorageService.set(types.SET_TICKET, payload);
  }
};
export { mutations };
