export const SET_CERTIFICATE = "SET_CERTIFICATE";

export interface ICertificate {
  readonly id: string;
  user_id: string;
  payment_id: string;
  year: string;
  approved: number;
}

export interface CertificateState {
  certificates: ICertificate[];
}
