import http from "./client";

class ProductActions {
  async getTicket() {
    const response = await http.get("product/ticket");

    return response.data;
  }
}

export default new ProductActions();
