import { RouteRecordRaw } from "vue-router";
import { store } from "../../store";

export default [
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("./index.vue"),
    beforeEnter: (to, from, next) => {
      const isAuthenticated: boolean = store.getters["user/isAuthenticated"];

      if (isAuthenticated) {
        next({ path: "/" });
      } else {
        next();
      }
    }
  }
] as Array<RouteRecordRaw>;
