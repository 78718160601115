import { createStore } from "vuex";

import { checkout, CheckoutState } from "./checkout";
import { user, UserState } from "./user";
import { product, ProductState } from "./product";
import { certificate, CertificateState } from "./certificate";
import { initStore } from "./InitStore";

const store = createStore({
  modules: {
    checkout,
    user,
    product,
    certificate
  }
});

interface State {
  checkout: CheckoutState;
  user: UserState;
  product: ProductState;
  certificate: CertificateState;
}

export { store, State, initStore };
