import _http from "./client";

import user from "./UserActions";
import product from "./ProductActions";
import certificate from "./CertificateActions";
import order from "./OrderActions";
import pagSeguro from "./PagSeguroActions";

const http = {
  ..._http,
  user,
  product,
  order,
  pagSeguro,
  certificate
};

export { http };
