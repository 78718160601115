import { http } from "@/http";
import { ICheckoutPayment, ICheckoutProduct } from "@/store/checkout/types";
import { IUser } from "@/store/user/types";
import { IPayment } from "../interface";
import { IPaymentEnum, paymentEnum } from "@/utils/enums/paymentEnum";
import {
  IBrand,
  IPaymentMethods,
  IInstallments,
  ICardTokenData,
  ICardToken
} from "./interfaces";

const ICardTypeBypass = {
  mastercard: "mastercard",
  visa: "visa",
  amex: "amex",
  dinersclub: "diners",
  discover: "discover",
  hipercard: "hipercard",
  aura: "aura",
  elo: "elo"
};

class PagSeguro implements IPayment {
  // Instance of PagSeguro
  private static instance: PagSeguro;
  private session = "";
  private senderHash = "";
  private lib: any = null;

  /**
   * Process payment data to send to payment endpoint
   * @param user IUser
   * @param payment ICheckoutPayment
   * @returns boolean
   */
  async processPayment(
    user: IUser,
    products: ICheckoutProduct[],
    payment: ICheckoutPayment
  ): Promise<boolean> {
    try {
      const { method, cardNumber, cardType, cardCvv, cardMonth, cardYear } =
        payment;
      const brand = ICardTypeBypass[cardType] || cardType;
      await this.setSessionId();
      const hash: string = await this.onSenderHashReady();

      const cardToken: ICardToken = await this.createCardToken({
        cardNumber: cardNumber.replace(/\s/g, ""),
        brand,
        cvv: cardCvv,
        expirationMonth: cardMonth,
        expirationYear: cardYear
      });

      const _payment = {
        ...payment,
        cardType: brand,
        method: this.paymentMethodByPass(method)
      };

      await http.pagSeguro.payment({
        user_id: user.id,
        products,
        payment: _payment,
        hash,
        cardToken: cardToken.card.token
      });

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Get redirect checkout page
   * @param user IUser
   * @param products IProducts
   * @returns string
   */
  async getCheckoutRedirectPage(
    user: IUser,
    products: ICheckoutProduct[]
  ): Promise<boolean> {
    try {
      return await http.pagSeguro.getRedirectCheckout({
        user_id: user.id,
        products,
        payment: {
          method: paymentEnum.CREDIT_CARD
        }
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Inject PagSeguro lib in HTML
   * @returns Boolean
   */
  public inject(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");

      script.setAttribute("src", process.env.VUE_APP_PAGSEGURO_LIB);

      script.setAttribute("async", "");

      script.onload = () => {
        this.lib = window["PagSeguroDirectPayment"];
        resolve(true);
      };
      script.onerror = () => {
        reject(false);
      };

      document.head.appendChild(script);
    });
  }

  /**
   * Set new session Id;
   * @returns boolean
   */
  public async setSessionId(): Promise<boolean> {
    if (!this.session.length) {
      const response = await http.pagSeguro.session();
      this.session = response.data.session[0];
      this.lib.setSessionId(this.session);
    }

    return true;
  }

  /**
   * Get for supported Payment Methods
   * @returns Payment Methods
   */
  public async getPaymentMethods(amount?: number): Promise<IPaymentMethods> {
    await this.setSessionId();

    return new Promise((resolve, reject) => {
      const data = {
        success: (response) => resolve(response),
        error: (error) => reject(error)
      };

      if (amount) {
        data["amount"] = amount;
      }

      this.lib.getPaymentMethods(data);
    });
  }

  /**
   * Get by sender hash
   * @returns Sender hash
   */
  public async onSenderHashReady(): Promise<string> {
    if (this.senderHash.length) {
      return this.senderHash;
    }

    return new Promise((resolve, reject) => {
      this.lib.onSenderHashReady((response) => {
        if (response.status == "error") {
          console.error(response.message);
          reject(false);
        }

        this.senderHash = response.senderHash;
        resolve(response.senderHash);
      });
    });
  }

  /**
   * Check by credit card brand
   * @param number Credit Card Number
   * @returns Object with brand info;
   */
  public getBrand(cardBin): Promise<IBrand> {
    return new Promise((resolve, reject) => {
      const data = {
        cardBin,
        success: (response) => resolve(response),
        error: (error) => reject(error)
      };
      this.lib.getBrand(data);
    });
  }

  /**
   * Get installment by amount and brand
   * @param amount number
   * @param maxInstallmentNoInterest number
   * @param brand string
   * @returns Installments info
   */
  public async getInstallments(
    amount: number,
    maxInstallmentNoInterest: number,
    brand: string
  ): Promise<IInstallments> {
    await this.setSessionId();
    return new Promise((resolve, reject) => {
      this.lib.getInstallments({
        amount,
        maxInstallmentNoInterest,
        brand: ICardTypeBypass[brand] || brand,
        success: (response) => resolve(response.installments[brand]),
        error: (error) => reject(error)
      });
    });
  }

  /**
   * Get CardToken for safety transition
   * @param param ICardTokenData
   * @returns Card Token
   */
  public createCardToken({
    cardNumber,
    brand,
    cvv,
    expirationMonth,
    expirationYear
  }: ICardTokenData): Promise<ICardToken> {
    return new Promise((resolve, reject) => {
      this.lib.createCardToken({
        cardNumber,
        brand,
        cvv,
        expirationMonth,
        expirationYear,
        success: (response) => resolve(response),
        error: (error) => reject(error)
      });
    });
  }

  /**
   * Return the PagSeguro instance and create it if not exist.
   * @returns PagSeguro Instance
   */
  public static getInstance(): PagSeguro {
    if (!PagSeguro.instance) {
      PagSeguro.instance = new PagSeguro();
    }

    return PagSeguro.instance;
  }

  /**
   * ByPass payment method
   * @param method string
   * @returns PagSeguro payment method
   */
  private paymentMethodByPass(method: IPaymentEnum) {
    if (method === paymentEnum.CREDIT_CARD) {
      return "creditCard";
    }
    if (method === paymentEnum.BILLET) {
      return "boleto";
    }
    if (method === paymentEnum.DEBIT) {
      return "eft";
    }
    if (method === paymentEnum.PIX) {
      return "pix";
    }
  }
}

// Export PagSeguro instance
export const pagSeguro = PagSeguro.getInstance();
