import { RouteRecordRaw } from "vue-router";
import { store } from "../../store";

export default [
  {
    path: "/profile",
    name: "Profile",
    component: () => import("./index.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      const isAuthenticated: boolean = store.getters["user/isAuthenticated"];

      if (!isAuthenticated) {
        next({ path: "/" });
      } else {
        next();
      }
    }
  }
] as Array<RouteRecordRaw>;
