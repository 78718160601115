import { IUser } from "@/store/user/types";
import http from "./client";

export interface IPaymentData {
  user: IUser;
  payment: IPaymentData;
  hash: string;
  cardToken: string;
}
class PagSeguroActions {
  private _url = "payment/pagseguro/";

  async session() {
    const response = await http.get(this._url + "session");
    return response.data;
  }

  async payment(data) {
    const response = await http.get(this._url + "transaction/creditcard", data);
    return response.data;
  }

  async getRedirectCheckout(data) {
    const response = await http.post(this._url + "redirect-checkout", data);
    return response.data;
  }
}

export default new PagSeguroActions();
