import i18n from "../i18n/";

/**
 * Convert float to price
 * @param value
 * @returns string
 */
export const priceFormat = (value: number): string => {
  const language = i18n.global.t("general.language");
  const currency = i18n.global.t("general.currency");

  const formatter: Intl.NumberFormat = new Intl.NumberFormat(language, {
    style: "currency",
    currency
  });

  return formatter.format(value);
};

/**
 * Increases date hour
 *
 * @param numOfHours
 * @param date
 * @returns Date
 */
export const addHours = (numOfHours, date = new Date()) => {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

  return date;
};

/**
 * Format date by i18n
 * @param date
 * @returns string
 */
export const formatDate = (date: Date) => {
  const _date = {
    day: ("0" + date.getDate()).slice(-2),
    month: ("0" + (date.getMonth() + 1)).slice(-2),
    year: date.getFullYear().toString()
  };

  return i18n.global
    .t("general.dataFormat")
    .replace("dd", _date.day)
    .replace("mm", _date.month)
    .replace("yyyy", _date.year);
};

/**
 * Convert input date to database date
 * @param string
 * @returns string
 */
export const inputDateToDatabaseDate = (date: string) => {
  const format = i18n.global.t("general.dataFormat").split("/");

  const _date = date.split("/");
  const dd = _date[format.indexOf("dd")];
  const mm = _date[format.indexOf("mm")];
  const yyyy = _date[format.indexOf("yyyy")];

  return `${yyyy}-${mm}-${dd}T12:00:00.000Z`;
};

/**
 * Convert database date to input date
 * @param string
 * @returns string
 */
export const databaseDateToInputDate = (date: string) => {
  const format: string = i18n.global.t("general.dataFormat");

  const _date = date.split("T")[0].split("-");

  const dd = _date[2];
  const mm = _date[1];
  const yyyy = _date[0];

  return format.replace("dd", dd).replace("mm", mm).replace("yyyy", yyyy);
};

/**
 * Replace all special Character
 * @param value String
 * @returns String normalized
 */
export const normalizeText = (value: string, replaceSpace?: string) => {
  let newValue = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  if (replaceSpace !== undefined) {
    newValue = newValue.replace(/\s/g, replaceSpace || "");
  }

  return newValue;
};

/**
 * Mail tester regex
 */
/* eslint-disable */
export const emailTest =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
