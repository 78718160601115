import { store } from "./index";
import { SessionStorageService } from "../services/sessionStorage.service";

import * as userTypes from "./user/types";
import { IUser } from "./user/types";

import * as productTypes from "./product/types";
import { IProduct } from "./product/types";

class InitStore {
  /**
   * Get and Update user store from Session Storage
   */
  public static getUserFromStorage() {
    const user: IUser = SessionStorageService.get(userTypes.SET_USER);
    if (user) {
      store.dispatch("user/setUser", user);
    }
  }

  /**
   * Get and Update ticket store from Session Storage
   */
  public static getTicketFromStorage() {
    const ticket: IProduct = SessionStorageService.get(productTypes.SET_TICKET);
    if (ticket) {
      store.dispatch("product/setTicket", ticket);
    }
  }
}

export const initStore = () => {
  InitStore.getUserFromStorage();
  InitStore.getTicketFromStorage();
};
