export const SET_TICKET = "SET_TICKET";

export interface IProduct {
  readonly id: string;
  code: string;
  description: string;
  endDate: string;
  name: string;
  price: number;
  startDate: string;
  created_at: string;
  updated_at: string;
}

export interface ProductState {
  ticket: IProduct;
}
