export default {
  general: {
    language: "pt-BR",
    currency: "BRL",
    money: "R$",
    dataFormat: "dd/mm/yyyy",
    month: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ]
  },
  navbar: {
    links: {
      more: "Mais",
      home: "Home",
      tickets: "Pacotes",
      events: "Eventos",
      about: "Sobre o SM",
      contact: "Contato",
      certificate: "Certificado",
      teachers: "Professores",
      djs: "DJs",
      team: "Equipe",
      login: "Entrar",
      logout: "Sair",
      dance_pac: "Bailes",
      privacy: "Privacidade",
      classes: "Aulas",
      terms: "Termos de uso",
      voteInTransit: "Voto em trânsito"
    }
  },
  home: {
    banner: {
      coming_soon: "Reserve o seu pacote por aqui em breve.",
      button_text: "Comprar pacote SM",
      date: {
        to: "de"
      },
      news_title: "Últimas novidades"
    }
  },
  contact: {
    title: "Contato",
    form: {
      fullname: "Nome completo",
      mail: "E-mail",
      whatsapp: "Whatsapp",
      subject: "Assunto",
      message: "Sua mensagem aqui"
    },
    info: {
      whatsapp: {
        number: () => "+55 21 96635-1912",
        url: () => "https://api.whatsapp.com/send?phone=5521966351912"
      },
      mail: {
        address: () => "contato@sambamaniacos.com.br"
      }
    },
    notification: {
      thanks: "",
      name: "Informe o seu nome por favor.",
      mail: "O seu e-mail é importante!",
      phone: "Vamos conversar por telefone? Informe-o por favor.",
      subject: "Diga-nos qual é o assunto",
      message: "Deixe sua mensagem.",
      congrats: "Eba!",
      required: "Campo obrigatório!",
      sent: "Sua menagem foi enviada, logo retornaremos! ;)",
      wops: "Ops!",
      failed:
        "Não foi possível enviar sua mensagem. <br><br> Entre em contato conosco por algum dos nossos canais sociais. ;)"
    }
  },
  footer: {
    reserved: "Todos os direitos reservados.",
    with_love: {
      first: "Feito com",
      second: "por",
      by: "Felipe"
    }
  },
  not_found: {
    back_to_home: "Ir para a página inicial."
  },
  checkout: {
    title: "Checkout",
    summary: "Resumo",
    summary_button: "Continuar compra",
    total: "Total",
    quantity: "Quantidade",
    quantity_short: "Quant.",
    "payment-methods": {
      "pag-seguro": {
        title: "Pag Seguro",
        alerts: {
          link_generate_erro: "Ocorreu um erro ao gerar o link de pagamento"
        }
      },
      "credit-card": {
        title: "Cartão de crédito",
        form: {
          name: "Nome do titular do cartão",
          number: "Número do cartão",
          validity: "Validade",
          cv: "CV",
          month: "Mês",
          year: "Ano",
          installment: "Parcelas",
          cpfCnpj: "CPF"
        },
        required: {
          cardName: "Nome do proprietário do cartão não informado.",
          cardNumber: "Número do cartão não informado.",
          cardMonth: "Mês de validade do cartão não informada.",
          cardYear: "Ano de validade do cartão não informada.",
          cardCvv: "Código de segurança não informado.",
          cardInstallmentValue: "Quantidade de parcelas não informada.",
          cardType: "Bandeira do cartão não informada.",
          cardCpfCnpj: "CPF não informado."
        },
        alerts: {
          link_generate_erro:
            "Ocorreu um erro ao tentar gerar o link de pagamento."
        }
      },
      billet: {
        title: "Boleto"
      },
      pix: {
        title: "Pix"
      },
      paypal: {
        title: "PayPal"
      },
      deposit: {
        title: "PIX ou Transferência"
      }
    },
    success: {
      pag_seguro: {
        title: "Pedido realizado com sucesso",
        order_number: "Número do pedido:",
        description:
          "Fique atento ao seu e-mail, em breve você receberá informações sober a sua compra."
      },
      credit_card: {
        title: "Compra realizada com sucesso",
        description:
          "Fique atento ao seu e-mail, em breve você receberá informações sober a sua compra."
      },
      billet: {
        title: "Boleto gerado com sucesso",
        code: "Código do boleto:",
        description:
          "A confirmação de pagamento pode acontecer em até 72 horas",
        button: "Baixar boleto"
      },
      pix: {
        title: "Pagamento via PIX",
        code: "Código Pix:",
        description: "A confirmação de pagamento pode acontecer em até 30 min"
      },
      deposit: {
        title: "Pagamento via PIX ou Transferência",
        code: "Código Pix:",
        description: "A confirmação de pagamento pode acontecer em até 30 min"
      }
    },
    alert: {
      paymentNotFound: "Nenhum método de pagamento definido.",
      creditCardInvalid: "Dados do cartão inválido",
      creditCardNotIdentified: "Não conseguimos validar os dados do seu cartão."
    }
  },
  certificate: {
    title: "Certificado",
    description:
      "Os certificados SM estão <b>disponíveis em sua conta</b>, basta realizar o <b>login</b> e entrar na página de perfil do usuário, lá será possível encontrar a <b>sessão de certificados</b>.",
    warn: {
      title: "Atenção",
      description:
        "Para os congressistas que <strong>não</strong> fizeram suas inscrições pelo site podem baixá-lo clicando "
    },
    generate: {
      title: "Solicitar Certificado",
      placeholder: "Nome Completo",
      button: "Solicitar",
      button_loading: "Solicitando..."
    },
    alert: {
      success: "Certificado gerado com sucesso.",
      error: "Não foi possível baixar o certificado."
    }
  },
  tickets: {
    title: "Pacote Samba Maníacos {year}",
    until: "Até o dia",
    limited:
      "Apenas 20 vagas disponíveis para o lote no escuro, adquira já a sua por este preço.",
    empty: "Não há pacotes disponíveis no momento",
    data: "<strong>{day}</strong> de <strong>{month}</strong> de {year}",
    registered: "Você já possui uma inscrição ativa.",
    included: {
      description: "Está incluso no pacote:",
      items: [
        "- Acesso a todas as aulas;",
        "- Certificado Samba Maníacos {year};",
        "- Bailes de Sexta e Sábado com banda ao vivo.",
        "- E uma experiência incrível;"
      ]
    },
    buttons: {
      buy: "Comprar",
      sm_dance: "Ver pacote de bailes"
    }
  },
  about: {
    title: "Sobre o Samba Maníacos",
    text: `O Samba Maníacos é um evento cujo objetivo principal é promover o samba de gafieira e suas vertentes.
    <br/><br/>
    Nascido em São Paulo, em 2011, e desde 2014 tendo como sede o Rio de Janeiro, o Sambamaníacos foi e é um dos grandes promotores da nossa dança, da nossa cultura brasileira, através de eventos dançantes compostos de aulas, bailes e palestras, tanto em sua cidade-sede quanto em outras cidades do Brasil, tendo, inclusive, realizado uma edição internacional em Lyon e está indo para a sua segunda edição em Galícia na Espanha.
    <br/><br/>
    Em muitas de nossas edições, também temos a participação de artistas populares entre o público da gafieira, tais como João Sabiá, Fernando Ébano, Joey Mattos (do programa The Voice Brasil), Walmir Borges, Dhema, entre outros.
    <br/><br/>
    Para a realização de nossa missão, contamos com uma equipe de produção compromissada e proativa, todos envolvidos profundamente com a dança de salão e o samba de gafieira, buscando sempre unir os amantes de samba, profissionais e estudantes, em nossos eventos. Portanto, buscar a união e a difusão do nosso samba pelo Brasil e pelo mundo.
    <br/><br/>
    O Samba Vai Dominar O Mundo!`,
    button: "Conhecer equipe SM"
  },
  dance_pack: {
    title: "Pacote de bailes Samba Maníacos",
    option:
      "Tá afim de curtir só os bailes do Sambamaíacos? Temos a opção perfeita pra você.",
    get: "Adquira a entrada para os 4 bailes por apenas:",
    just_for: "Obs: Apenas para quem não adquiriu o pacote SM!",
    acquired:
      "Se você já é um Maníaco pode estar adquirindo as entradas de quinta e domingo na portaria do evento!",
    deposit: {
      title: "Dados para depósito:",
      data: {
        bank: "Banco: ",
        bank_info: " 290 - PagSeguro Internet S.A.",
        agency: "Agência: ",
        agency_info: " 0001",
        account: "Número da conta: ",
        account_info: " 07228467-2",
        type: "Tipo: ",
        type_info: " Conta de pagamento",
        cpf: "CPF: ",
        cpf_info: " 058.046.577-20"
      },
      pix: {
        title: "Chave PIX:",
        key: "sambamaniacos{'@'}yahoo.com.br"
      },
      receipt: {
        text: "Envie o comprovante e os dados pessoais para o Whatsapp:",
        number: "(21) 9 7669-7145",
        name: "(Mylena Venceslau);"
      }
    },
    buttons: {
      buy: "Comprar",
      sm_dance: "Ver pacote Sambamaníacos"
    }
  },
  login: {
    title: "Login",
    buttons: {
      login: "Login",
      forgot: "Esqueceu a senha?",
      create: "Cadastrar"
    },
    inputs: {
      email: "E-mail",
      password: "Senha"
    },
    alert: {
      email: "E-mail inválido.",
      password: "Senha não informada."
    },
    account: "Não possui conta ainda?",
    or: "ou"
  },
  forgot: {
    title: "Esqueci a senha",
    description:
      "Um e-mail será enviado com instruções para a redefinição da  senha",
    buttons: {
      recover: "Recuperar",
      login: "Login",
      create: "Cadastrar"
    },
    inputs: {
      email: "E-mail",
      password: "Senha"
    },
    alert: {
      email: "E-mail inválido.",
      email_sent: "E-mail enviado.",
      password: "Senha não informada."
    },
    account: "Não possui conta ainda?",
    or: "ou"
  },
  reset: {
    title: "Redefinir senha",
    buttons: {
      save: "Salvar"
    },
    inputs: {
      password: "Senha:",
      password_confirm: "Confirmar Senha:"
    },
    required: {
      password: "Senha não informado.",
      password_confirm: "Confirmação de senha não informada.",
      password_conference: "As senhas não são compatíveis."
    },
    alert: {
      email: "E-mail inválido.",
      success: "Senha atualizada."
    }
  },
  expired: {
    title: "Redefinição de senha expirada",
    description:
      "Este link de recuperação não é mais válido, tente gerar um novo link.",
    buttons: {
      forgot: "Ir para esqueci minha senha"
    }
  },
  create: {
    title: "Cadastro",
    description_one: "Dados Pessoais",
    description_Two: "Dados de acesso",
    buttons: {
      create: "Cadastrar",
      login: "Login",
      next: "Próximo",
      previous: "Voltar"
    },
    inputs: {
      name: "Nome:",
      phone: "Telefone:",
      country: "País:",
      state: "Estado:",
      city: "Cidade:",
      cpf: "CPF:",
      birthday: "Data de nascimento:",
      email: "E-mail",
      email_confirm: "Confirmar E-mail:",
      password: "Senha:",
      password_confirm: "Confirmar Senha:"
    },
    required: {
      name: "Nome não informado.",
      lastname: "Sobrenome não informado.",
      phone: "Telefone não informado.",
      country: "País não informado.",
      state: "Estado não informado.",
      city: "Cidade não informado.",
      cpf: "CPF não informado.",
      birthday: "Data de nascimento não informada.",
      birthday_invalid: "Data de nascimento informada é inválida.",
      email: "E-mail não informado.",
      email_confirm: "E-mail não informado.",
      email_conference: "Os e-mails não são compatíveis.",
      email_invalid: "E-mail inválido.",
      password: "Senha não informado.",
      password_length: "A senha precisa ter no mínimo 6 dígitos.",
      password_confirm: "Confirmação de senha não informada.",
      password_conference: "As senhas não são compatíveis."
    },
    alerts: {
      success: "Cadastro realizado com sucesso!",
      countries_not_found: "Não conseguimos consultar os países!",
      states_not_found:
        "Não conseguimos encontrar estados para o país selecionado!",
      cities_not_found:
        "Não conseguimos encontrar cidades para o estado selecionado!"
    },
    account: "Já possui uma conta?"
  },
  notifications: {
    required: "Obrigatório!",
    warn: "Aviso!",
    error: "Erro!",
    info: "Informação!",
    success: "Sucesso!"
  },
  teachers: {
    title: "Professores",
    description: `
    O Sambamaniacos todos os anos reúne os melhores profissionais do mercado em um só lugar, para promover a cultura do nosso amado samba, com o que tem de mais atualizado no mercado, sempre prezando para que a tradição também continue viva.
    <br/><br/>
    O lugar onde a nova geração e a vanguarda se encontram. Tudo em prol de manter viva a nossa essência e também dar asas para o novo. 
    <br/><br/>
    São aulas de samba de gafieira, samba Funkeado, samba no pé, danças africanas, palestras históricas, aulas para condutores, aulas para conduzidas, aulas de lady style, aulas de capoeira e muito mais. Todo ano trazemos novidades, com os melhores profissionais que temos no mercado. 
    <br/><br/>
    Todos os anos, os profissionais que farão parte do nosso elenco anual, são escolhidos a dedo em comum acordo entre todos os nossos membros de produção. 
    <br/><br/>
    O Sambamaniacos também, desde a sua criação, sempre prezou por dar oportunidade para os profissionais que estão começando a carreira, desde que, estejam devidamente preparados para oferecer o melhor para os nossos congressistas. 
    <br/><br/>
    Todos os anos, ouvimos as opiniões dos nossos congressistas e nosso público, prezando muito pelo feedback dado. Seja positivo ou negativo. E estamos sempre buscando por melhorias para oferecer o melhor a cada ano.
    `
  },
  djs: {
    title: "DJs",
    description: `
    O Sambamaniacos preza sempre pela qualidade dos DJs que comandam a pista dos nossos bailes. 
    <br/><br/>
    Com uma política de termos sempre um DJ residente com a cara do evento, sem deixarmos de dar oportunidade também para que outros profissionais toquem no nosso salão. 
    <br/><br/>
    Sempre fazendo um trabalho de estudo e construção do DJ residente, para que a cada baile, esse DJ fique cada vez mais com a cara do nosso evento.
    <br/><br/>
    Inúmeros DJs já passaram por aqui. 
    E estamos sempre de olho naqueles que estão ganhando o mercado.
    <br/><br/>
    Atualmente, contamos com o nosso querido DJ Cleber Guimarães, como residente. Que tem feito um excelente trabalho como nosso DJ OFICIAL. Se dedicando e estudando constantemente e se aprimorando a cada baile que passa.
    <br/><br/>
    Também pensamos muito na importância da representatividade em todas as áreas. E não poderia ser diferente com nosso time de DJs. Com tantos talentos por aí e com tantas DJs extremamente competentes no nosso cenário, fazemos questão de sempre que possível termos uma DJ no nosso elenco. E atualmente, contamos com a talentosíssima DJ Vanessa Alves abrilhantando ainda mais o nosso time.
    <br/><br/>
    E sempre damos uma atenção também para aquela galera que gosta de um Zouk. Atualmente, no comando do nosso Lounge de Zouk, feito especialmente pra você que quer relaxar e viajar dançando cremosinho, temos o aclamado DJ Thiagão. Sempre presente nos melhores bailes de Zouk pelo Brasil. 
    <br/><br/>
    No Sambamaniacos, você conta sempre com os melhores DJs, escolhidos com muito cuidado, para trazer o melhor dos bailes para vocês!!!
    `
  },
  team: {
    title: "Equipe Samba Maníacos",
    description: `
    A equipe de produção do Sambamaníacos hoje é composta por 07 membros, mas ao longo desses quase 12 anos, desde a criação do evento, muitas pessoas importantes já passaram por aqui. 
    <br/><br/>
    A produção é formada por pessoas que amam o samba, amam o Sambamaníacos e que acreditaram e compraram o sonho do diretor do evento, Leo Fortes. Essas pessoas vestiram a camisa do Sambamaníacos e sempre se dedicaram para a realização das ideias propostas e para entregar o melhor possível para todo o público do evento.
    <br/><br/>
    Temos pessoas que estão aqui desde o dia 01, desde a criação do Sambamaníacos lá em 2010, que se dedicaram todos esses anos em prol do evento, como é o caso do nosso capitão, Pablo. O Pablo junto com sua companheira e nossa antiga capitã, Clarinha, foram os pioneiros dessa equipe e fizeram parte de toda a evolução do Sambamaníacos, até se tornar o evento que é hoje. Nosso pequeno ‘’Pipi’’, nosso Pietro, filho deles, participa do Sambamaníacos desde que estava na barriga da mamãe. E mesmo ainda de colo, esteve presente em todos os eventos, acompanhando o brilhante trabalho de seus pais.
    <br/><br/>
    Temos pessoas que chegaram alguns anos depois, como é o caso do Márcio, nosso vendedor estrela. Quem é que já foi ao congresso e não ficou tentado a comprar pelo menos uma peça de roupa da nossa grife sendo anunciada por ele? Ele que entre um trabalho e outro, sempre encontrava um jeito de auxiliar o evento. E Carol, sua companheira, que está sempre pensando em propostas interessantes para o evento. Lembro que a primeira vez que eu vi a Carol, foi num baile do Sambamaníacos no CIB, meu primeiro baile do SM quando eu estava começando a dançar lá em 2015 e eu fiquei encantado com aquela menina que chamava todas as damas para dançar. 
    <br/><br/>
    Mais alguns anos e tivemos a ideia de montar um staff, uma equipe secundária para auxiliar nossa Produção na realização dos eventos. Desse staff surgiram Jean e Mylena, outros dois membros que nunca se poupam em entregar o melhor para o SM. E como não poderia ser diferente, seu bem mais precioso, Pedro Henrique, está presente no Sambamaníacos desde que estava na barriga da mamãe Mylena.
    <br/><br/>
    E em 2022 acrescentamos mais um membro à equipe, a Pegada.com! A empresa vem produzido materiais de divulgações online e offline em forma de artes e vídeos para alimentar nossas redes, divulgar e disseminar os ideais do nosso evento. Comandada pela Natalia Bellinha, a Pegada.com junto com o SambaManíacos é a união que não teria como dar errado!
    <br/><br/>
    A essa altura já deu para perceber do que se trata a produção do Sambamaníacos, né? Somos uma família! Cada membro da produção foi escolhido a dedo pelo diretor Leo Fortes, pois em sua visão, eram pessoas que tinham algo de especial que poderia ser bem aproveitado pelo Sambamaníacos. Todos unidos e trabalhando em prol de um mesmo objetivo: Através do Sambamaníacos, seja quais forem as condições e as circunstâncias, proporcionar uma experiência inesquecível para o nosso público.
    <br/><br/>
    Membro da produção, Rodrigo Gonçalves.
    `
  },
  profile: {
    sambamaniacos: "Sambamaníacos",
    registered: "Inscrito",
    buttons: {
      tickets: "Comprar agora",
      logout: "Sair da conta",
      back: "voltar"
    },
    menu: {
      my_data: "Meus Dados",
      certificate: "Certificado",
      orders: "Minhas Compras",
      faq: "Perguntas Frequentes"
    },
    my_data: {
      personal_data: "Dados Pessoais",
      access_data: "Dados de acesso",
      inputs: {
        name: "Nome:",
        phone: "Telefone:",
        country: "País:",
        state: "Estado:",
        city: "Cidade:",
        cpf: "CPF:",
        birthday: "Data de nascimento:",
        email: "E-mail",
        password: "Nova Senha:",
        password_confirm: "Confirmar Senha:"
      },
      buttons: {
        save: "Atualizar"
      },
      alerts: {
        successfully_updated: "Seus dados foram atualizados com sucesso."
      }
    },
    faq: {
      list: [
        {
          title: "Posso comprar a entrada no evento?",
          content: `
            Pode sim, desde que ainda haja unidades disponíveis a venda.
            <br/><br/>
            A compra do pacote SM no local do evento terá um valor maior do que se comprado antecipadamente, sendo assim sugiro ficar atento as datas dos lotes para conseguir um bom valor.
          `
        },
        {
          title:
            "Comprei meu pacote SM mas não poderei ir, posso pedir meu dinheiro de volta?",
          content: `
            Pode sim, desde que ainda haja unidades disponíveis a venda.
            <br/><br/>
            A compra do pacote SM no local do evento terá um valor maior do que se comprado antecipadamente, sendo assim sugiro ficar atento as datas dos lotes para conseguir um bom valor.
          `
        },
        {
          title: "Como faço para transferir meu Pacote para outra pessoa?",
          content: `
            Pode sim, desde que ainda haja unidades disponíveis a venda.
            <br/><br/>
            A compra do pacote SM no local do evento terá um valor maior do que se comprado antecipadamente, sendo assim sugiro ficar atento as datas dos lotes para conseguir um bom valor.
          `
        },
        {
          title: "Como faço para conseguir o certificado?",
          content: `
            Pode sim, desde que ainda haja unidades disponíveis a venda.
            <br/><br/>
            A compra do pacote SM no local do evento terá um valor maior do que se comprado antecipadamente, sendo assim sugiro ficar atento as datas dos lotes para conseguir um bom valor.
          `
        }
      ]
    },
    orders: {
      empty: "Você ainda não possui nenhum pedido"
    },
    certificate: {
      not_found: "Nenhum certificado encontrado.",
      certificate: "Certificado",
      alert: {
        download_error: "Não foi possível baixar o certificado."
      }
    }
  },
  privacy: {
    title: "Termos de privacidade"
  },
  classes: {
    title: "Grade de Aulas"
  },
  voteInTransit: {
    title: "Voto em trânsito"
  },
  terms: {
    title: "Termos de uso"
  }
};
