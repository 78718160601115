import { createI18n } from "vue-i18n";
import ptBR from "./langs/ptBR";
import enUS from "./langs/enUS";

const i18n = createI18n({
  locale: "ptBR",
  messages: {
    ptBR,
    enUS
  }
});

export default i18n;
