<template>
  <section id="cookie-popup" v-show="!accepted">
    <div>
      <p>
        Nós e os terceiros selecionados usamos cookies ou tecnologias similares
        para finalidades técnicas e, com seu consentimento, para outras
        finalidades, conforme especificado na
        <router-link class="btn-link" to="/privacy">
          política de privacidade
        </router-link>
        . Você poderá consentir o uso de tais tecnologias ao usar o botão
        “Aceitar”. A sua permanência neste site consente com este aviso.
      </p>

      <div class="buttons">
        <router-link class="btn-link-none" to="/privacy">
          Saiba mais
        </router-link>
        <button class="btn-link-green" @click="accept">Aceitar</button>
      </div>
    </div>
  </section>
</template>

<script>
import { CookieStorageService } from "@/services/cookieStorage.service";
import { eventsEnum } from "@/utils/enums";

export default {
  name: "CookiePopup",
  data: () => ({
    accepted: false
  }),
  methods: {
    accept() {
      CookieStorageService.set(eventsEnum.COOKIE_PRIVACY_ACCEPTED, true);
      this.accepted = true;
    }
  },
  mounted() {
    const accepted = CookieStorageService.get(
      eventsEnum.COOKIE_PRIVACY_ACCEPTED
    );
    this.accepted = accepted;
  }
};
</script>

<style lang="scss">
#cookie-popup {
  position: fixed;
  left: var(--spacing-15);
  right: var(--spacing-15);
  bottom: var(--spacing-15);
  z-index: 99999;
  padding: var(--spacing-30);
  background-color: var(--color-white);
  box-shadow: 0px 1px 12px rgb(0 0 0 / 40%);
  line-height: 1.5;
  border-radius: var(--border-radius-5);

  .buttons {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;

    .btn-link-none,
    .btn-link-gray,
    .btn-link-green {
      padding: var(--spacing-10) var(--spacing-25);
      margin: 0 var(--spacing-10);
      border-radius: var(--border-radius-5);
    }
  }

  .btn-link {
    text-decoration: underline;
  }
  .btn-link-none {
    border: 1px solid var(--color-gray-light);
    background-color: var(--color-white);
  }

  .btn-link-green {
    background-color: var(--color-green-base);
  }
}
</style>
