import * as types from "./types";
import { CertificateState, ICertificate } from "./types";
import { SessionStorageService } from "../../services/sessionStorage.service";

const mutations = {
  [types.SET_CERTIFICATE](state: CertificateState, payload: ICertificate[]) {
    state.certificates = payload;

    SessionStorageService.set(types.SET_CERTIFICATE, payload);
  }
};
export { mutations };
