import { createApp } from "vue";
import { store, initStore } from "./store";

import App from "./App.vue";
import InlineSvg from "vue-inline-svg";
import events from "@/plugins/Events";
import Notifications from "@/plugins/Notifications";
import SimpleTypeahead from "vue3-simple-typeahead";
import { isMobile } from "@/plugins/IsMobile";
import { pagSeguro } from "@/providers/payment/pagseguro";

import router from "./router";
import i18n from "./i18n";

import "./assets/scss/base.scss";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";

initStore();
pagSeguro.inject();

createApp(App)
  .mixin(isMobile)

  .use(store)
  .use(router)
  .use(i18n)
  .use(events)
  .use(Notifications)
  .use(SimpleTypeahead)

  .component("inline-svg", InlineSvg)

  .mount("#app");

// Security Alternative Force HTTPS
if (!location.origin.includes("localhost") && location.protocol !== "https:") {
  location.replace(
    `https:${location.href.substring(location.protocol.length)}`
  );
}
