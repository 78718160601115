import * as types from "./types";
import { UserState, IUser } from "./types";
// import { SessionStorageService } from "../../services/sessionStorage.service";

const mutations = {
  [types.SET_USER](state: UserState, payload: IUser) {
    state.user = payload;

    // SessionStorageService.set(types.SET_USER, payload);
  },
  [types.SET_IS_REGISTERED](state: UserState, payload: Boolean) {
    state.isRegistered = payload;

    // SessionStorageService.set(types.SET_IS_REGISTERED, payload);
  },
  [types.LOGOUT_USER](state: UserState) {
    state.user = {} as IUser;
    state.isRegistered = false;

    // SessionStorageService.remove(types.SET_USER);
    // SessionStorageService.remove(types.SET_IS_REGISTERED);
  }
};
export { mutations };
