import axios from "axios";
import i18n from "../i18n";

export default axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-Type": "application/json",
    "accept-language": i18n.global.locale
  }
});
