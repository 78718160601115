// import * as types from "./types";
import { UserState } from "./index";
// import { SessionStorageService } from "../../services/sessionStorage.service";

const getters = {
  user(state: UserState) {
    return state.user;
  },
  isAuthenticated(state: UserState) {
    return !!state.user.token;
  },
  isRegistered(state: UserState) {
    return state.isRegistered;
  }
};
export { getters };
