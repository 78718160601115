import { ActionContext } from "vuex";
import {
  CheckoutState,
  ICheckoutProduct,
  ICheckoutCreditCard,
  ICheckoutOrder
} from "./types";
import * as types from "./types";
import { State } from "../";

type Context = ActionContext<CheckoutState, State>;

const actions = {
  /**
   *
   * @param context
   * @param payload
   */
  addProduct(context: Context, payload: ICheckoutProduct) {
    const product: ICheckoutProduct | undefined = context.state.products.find(
      (product: ICheckoutProduct) => product.code === payload.code
    );

    if (product && product.quantity === payload.quantity) {
      return;
    }

    context.commit(types.ADD_PRODUCT, payload);
  },

  /**
   *
   * @param context
   * @param payload
   */
  savePaymentMethod(context: Context, payload: string) {
    context.commit(types.SAVE_PAYMENT_METHOD, payload);
  },

  /**
   *
   * @param context
   * @param payload
   */
  saveOrder(context: Context, payload: ICheckoutOrder) {
    context.commit(types.SAVE_ORDER, payload);
  },

  /**
   *
   * @param context
   * @param payload
   */
  saveCreditCard(context: Context, payload: ICheckoutCreditCard) {
    context.commit(types.SAVE_CREDITCARD, payload);
  },

  /**
   *
   * @param context
   * @param payload
   */
  setLoading(context: Context, payload: boolean) {
    context.commit(types.SET_LOADING, payload);
  }
};

export { actions };
