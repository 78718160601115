import { priceFormat } from "../../utils/";
import { CheckoutState } from "./types";

const getters = {
  total(state: CheckoutState) {
    let total = 0;

    state.products.forEach((product: any) => {
      total += product.price * product.quantity;
    });

    return priceFormat(total);
  },

  totalNumber(state: CheckoutState) {
    let total = 0;

    state.products.forEach((product: any) => {
      total += product.price * product.quantity;
    });

    return total;
  },

  products(state: CheckoutState) {
    return state.products.map((product: any) => {
      return product;
    });
  },

  creditCard(state: CheckoutState) {
    return state.creditCard;
  },

  paymentMethod(state: CheckoutState) {
    return state.paymentMethod;
  },

  order(state: CheckoutState) {
    return state.order;
  },

  loading(state: CheckoutState) {
    return state.loading || false;
  }
};

export { getters };
