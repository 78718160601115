<template>
  <Navbar />
  <main :style="{ 'background-image': 'url(' + background + ')' }">
    <router-view />
  </main>
  <Footer />
  <notifications />
  <CookiePopup />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import CookiePopup from "@/components/CookiePopup.vue";
import { http } from "@/http";
import { mapGetters, mapActions } from "vuex";
import { eventsEnum } from "@/utils/enums";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    CookiePopup
  },
  data: () => ({
    background: ""
  }),
  computed: {
    ...mapGetters({
      ticket: "product/ticket"
    })
  },
  methods: {
    ...mapActions("product", ["setTicket"])
  },
  async mounted() {
    this.$events.on(
      eventsEnum.SET_MAIN_BACKGROUND,
      (background) => (this.background = background)
    );

    try {
      if (!this.ticket?.code) {
        const response = await http.product.getTicket();
        this.setTicket(response.data);
      }

      this.hasTicket = true;
    } catch (error) {
      console.error(error.response?.data?.message || error);
    }
  }
};
</script>

<style lang="scss">
main {
  min-height: calc(100vh - var(--sizing-180));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.vue-notification-group {
  top: 90px !important;
  width: 400px !important;
}
.vue-notification {
  // styling
  margin: 0 var(--spacing-5) var(--spacing-5);
  padding: var(--spacing-15) var(--spacing-25) !important;
  color: var(--color-white);

  // default (blue)
  background: var(--color-notify-info);
  border-left: var(--border-radius-5) solid var(--color-notify-info-border);

  .notification {
    &-title {
      font-size: var(--font-size-16);
      margin-bottom: var(--spacing-5);
    }
    &-content {
      font-size: var(--font-size-16);
    }
  }

  &.success {
    background: var(--color-notify-success);
    border-left-color: var(--color-notify-success-border);
  }

  &.warn {
    background: var(--color-notify-warn);
    border-left-color: var(--color-notify-warn-border);
  }

  &.error {
    background: var(--color-notify-error);
    border-left-color: var(--color-notify-error-border);
  }
}
</style>
