import http from "./client";

class CertificateActions {
  async getCertificate(user_id: string) {
    const response = await http.get("certificate", { params: { user_id } });

    return response.data;
  }

  async getPDF(id: string) {
    const response = await http.get("certificate/generate", { params: { id } });

    return response.data;
  }

  async getPDFJustName(name: string) {
    const response = await http.get("certificate/generate-just-name", {
      params: { name }
    });

    return response.data;
  }
}

export default new CertificateActions();
