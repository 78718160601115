export type ISessionStorage = any | null;

export class SessionStorageService {
  public static set(key: string, value: object) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  public static get(key: string): ISessionStorage {
    try {
      const response = sessionStorage.getItem(key);
      return response ? (JSON.parse(response) as ISessionStorage) : null;
    } catch (error) {
      console.error("Couldn't convert to object.");
    }

    return null;
  }

  public static remove(key: string): void {
    sessionStorage.removeItem(key);
  }
}
