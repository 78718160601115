import { ActionContext } from "vuex";
import * as types from "./types";
import { ProductState, IProduct } from "./types";
import { State } from "../";

type Context = ActionContext<ProductState, State>;

const actions = {
  /**
   * Salva os dados do ticket na sessão
   * @param context
   * @param payload
   */
  setTicket(context: Context, payload: IProduct) {
    context.commit(types.SET_TICKET, payload);
  }
};
export { actions };
