import { IUser, IUserUpdate } from "../store/user/types";
import http from "./client";

class UserActions {
  async create(data: IUser) {
    const response = await http.post("user", data);

    return response.data;
  }

  async update(data: IUserUpdate) {
    const response = await http.put("user", data);

    return response.data;
  }

  async login({ email, password }: { email: string; password: string }) {
    const response = await http.post("login", {
      email,
      password
    });

    return response.data;
  }

  async forgot({ email }: { email: string }) {
    const response = await http.post("forgot", {
      email
    });

    return response.data;
  }

  async getIsRegistered({ user_id }) {
    const response = await http.get("payment/is-registered", {
      params: { user_id }
    });

    return response.data;
  }
}

export default new UserActions();
