import http from "./client";

class OrderActions {
  async get(user_id: string) {
    const response = await http.get("order", { params: { user_id } });

    return response.data;
  }
}

export default new OrderActions();
