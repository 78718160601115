<template>
  <ul class="social-links right">
    <li>
      <a
        href="https://www.youtube.com/results?search_query=sambamaniacos"
        @click="toggleMenu(false)"
        target="_black"
      >
        <inline-svg :src="IconYoutube" title="Youtube" />
      </a>
    </li>
    <li>
      <a
        href="https://twitter.com/sambamaniacos"
        @click="toggleMenu(false)"
        target="_black"
      >
        <inline-svg :src="IconTwitter" title="Twitter" />
      </a>
    </li>
    <li>
      <a
        href="https://www.instagram.com/sambamaniacos/"
        @click="toggleMenu(false)"
        target="_black"
      >
        <inline-svg :src="IconInstagram" title="Instagram" />
      </a>
    </li>
    <li>
      <a
        href="https://www.facebook.com/sambamaniacos.oficial"
        @click="toggleMenu(false)"
        target="_black"
      >
        <inline-svg :src="IconFacebook" title="Facebook" />
      </a>
    </li>
    <li v-if="showLogin" class="btn-header">
      <router-link
        class="profile"
        v-if="isAuthenticated"
        to="/profile"
        @click="toggleMenu(false)"
      >
        <inline-svg :src="IconProfile" title="Profile" />
      </router-link>
      <router-link v-else to="/login" @click="toggleMenu(false)">{{
        $t("navbar.links.login")
      }}</router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

import IconFacebook from "@/assets/img/icons/social/facebook.svg";
import IconInstagram from "@/assets/img/icons/social/instagram.svg";
import IconTwitter from "@/assets/img/icons/social/twitter.svg";
import IconYoutube from "@/assets/img/icons/social/youtube.svg";
import IconMail from "@/assets/img/icons/mail.svg";
import IconProfile from "@/assets/img/icons/profile.svg";

export default {
  name: "MenuSocialNavigation",
  props: ["toggleMenu", "showLogin"],
  data: () => ({
    IconFacebook,
    IconInstagram,
    IconTwitter,
    IconYoutube,
    IconMail,
    IconProfile
  }),
  computed: {
    ...mapGetters({
      isAuthenticated: "user/isAuthenticated"
    })
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toggleMenuAndScroll(value) {
      if (this.toggleMenu) {
        this.toggleMenu(value);
      }
      this.scrollToTop();
    }
  }
};
</script>

<style lang="scss"></style>
