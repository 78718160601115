import { RouteRecordRaw } from "vue-router";
import { store } from "../../store";
import { Notify } from "@/plugins/Notifications";

export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("./index.vue"),
    beforeEnter: (to, from, next) => {
      const isAuthenticated: boolean = store.getters["user/isAuthenticated"];

      if (isAuthenticated) {
        next({ path: "/" });
      } else {
        next();
      }
    }
  },

  {
    path: "/logout",
    name: "Logout",
    component: null,
    beforeEnter: (to, from, next) => {
      store.dispatch("user/removeUser");
      Notify.success("Deslogado com sucesso.");
      next({ path: "/" });
    }
  }
] as Array<RouteRecordRaw>;
