import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import { eventsEnum } from "@/utils/enums";
import { events } from "@/plugins/Events";

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(() => {
  // Reset main background;
  events.emit(eventsEnum.SET_MAIN_BACKGROUND, "");
});

export default router;
