import { RouteRecordRaw } from "vue-router";
import { store } from "../../store";
import CryptoJS from "crypto-js";

export default [
  {
    path: "/reset/:token",
    name: "Reset",
    component: () => import("./index.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      const isAuthenticated: boolean = store.getters["user/isAuthenticated"];
      const token: string = to.params.token as string;
      const data = decrypt(token);

      if (isExpired(data.expires)) {
        next({ path: "/expired" });
      }

      if (isAuthenticated || !data) {
        next({ path: "/" });
      } else {
        to.params.id = data.id;
        to.params.email = data.email;
        next();
      }
    }
  }
] as Array<RouteRecordRaw>;

function decrypt(token: string) {
  try {
    const bytes = CryptoJS.AES.decrypt(
      decodeURIComponent(token),
      process.env.VUE_APP_RESET_CRYPTO_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedData;
  } catch (error) {
    console.error(error);
    return false;
  }
}

function isExpired(expiresData: number) {
  const today = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: "America/Sao_Paulo"
    })
  );

  const expires = new Date(expiresData);

  if (today.getTime() >= expires.getTime()) {
    return true;
  }

  return false;
}
