<template>
  <section id="not-found">
    <div class="">
      <h1>Not Found</h1>
      <router-link to="/">{{ $t("not_found.back_to_home") }}</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
  data: () => ({}),
  components: {}
};
</script>

<style lang="scss">
#not-found {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    margin-bottom: 25px;
    font-size: 30px;
  }

  a {
    padding: 15px 25px;
    border-radius: var(--border-radius-5);
    transition: background 0.2s ease-in-out;

    &:hover {
      background: #f3f0f0;
    }
  }

  & > div {
    text-align: center;
  }
}
</style>
